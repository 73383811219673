import * as React from "react";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";

import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import HeroBanner from "../../../components/pages/solutions/HeroBanner";
import HomeExamples from "../../../components/pages/home/HomeExamples";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import HomeIdeation from "../../../components/pages/home/HomeIdeation";
import SectionLeftOverGreyBackground from "../../../components/pages/solutions/SectionLeftOverGreyBackground";
import SectionWithRightImage from "../../../components/pages/solutions/SectionWithRightImage";
import SectionWithVerticalImage from "../../../components/pages/solutions/SectionWithVerticalImage";
import Shoutout from "../../../components/pages/signup/Shoutout";
import Content from "../../../components/layout/Content/Content";

const ContentExtended = Styled(Content)`
  margin-top: 6rem;
`;

const Home = ({ data }) => {
  return (
    <Layout>
      <Meta
        title="Uizard For Developers | Uizard"
        description="The best design tool for developers to appraise app, web, and UI designs before development."
        url="/solutions/developers/"
      />

      <HeroBanner
        tagline="Developers"
        headline="Uizard for developers"
        description="Visualize and communicate ideas with your team like never before. Start with a clear vision and save countless dev hours later down the line."
        image={data.DevsCoverBig.childImageSharp}
        imageSmall={data.DevsCoverSmall.childImageSharp}
        imageAlt="mobile app and a website design with uizard"
      />

      <SectionWithVerticalImage
        headline="Throwaway prototyping"
        description="Spin out app ideas at lightning speed. Design screens, work on mockups, and master <a:https://uizard.io/prototyping/>UI prototyping</a> with an easy-to-use, self-served solution. Uizard provides you with a streamlined drag-and drop editor for designing and refining your ideas."
        imageA={data.DevsThrowawayProtoBig.childImageSharp}
        imageB={data.DevsThrowawayProtoSmall.childImageSharp}
        imageAlt="app prototyping with uizard"
      />

      <HomeIdeation
        headline="Stunning design styles without the hassle"
        description="Snap a screenshot of your inspiration and let our AI design features create a one-of-a-kind design style just for you."
        imageAlt="Image demonstrating that you can upload a screenshot and Uizard will apply the screenshot style to your project automatically."
        imageA={data.themeGenerationImageA.childImageSharp}
        imageB={data.themeGenerationImageB.childImageSharp}
        videoName="uizard-theme-generation"
        link=""
      />

      <SectionLeftOverGreyBackground
        headline="Smart collaboration"
        description="Collaboration is easy with Uizard. Invite team members to view and collaborate on your <a:https://uizard.io/ux-design/>UX design</a> project in real time. Whether you are refining ideas or sharing your project with internal or external stakeholders for sign off, we've got you covered."
        imageA={data.DevsSmartCollabBig.childImageSharp}
        imageB={data.DevsSmartCollabSmall.childImageSharp}
        imageAlt="collaborative prototyping with uizard"
      />

      <SectionWithRightImage
        headline="Streamline feedback"
        description="Don't wait days for feedback. Uizard's commenting system allows you to gather and analyze feedback as quickly as possible. Share your designs with clients, team members, or even potential customers."
        imageA={data.DevsStreamlineFeedbackBig.childImageSharp}
        imageB={data.DevsStreamlineFeedbackSmall.childImageSharp}
        imageAlt="ux prototype feedback"
      />

      <HomeFeatured />
      <HomeExamples />

      <ContentExtended>
        <Shoutout
          headline="Design like never before"
          description="Teamworking and project management, streamlined."
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    DevsCoverBig: file(relativePath: { eq: "developers/Devs_Cover_2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1014, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    DevsCoverSmall: file(relativePath: { eq: "developers/Devs_Cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 507, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    DevsThrowawayProtoBig: file(
      relativePath: { eq: "developers/Devs_ThrowawayProto_PNG_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    DevsThrowawayProtoSmall: file(
      relativePath: { eq: "developers/Devs_ThrowawayProto_PNG.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1070, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageA: file(
      relativePath: { eq: "home/uizard-theme-generation-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageB: file(
      relativePath: { eq: "home/uizard-theme-generation-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    DevsSmartCollabBig: file(
      relativePath: { eq: "developers/Devs_SmartCollab_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 860, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    DevsSmartCollabSmall: file(
      relativePath: { eq: "developers/Devs_SmartCollab.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 430, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    DevsStreamlineFeedbackBig: file(
      relativePath: { eq: "developers/Devs_StreamlineFeedback_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1152, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    DevsStreamlineFeedbackSmall: file(
      relativePath: { eq: "developers/Devs_StreamlineFeedback.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
